import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersiststate from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [new VuexPersiststate({key: 'rmd'})],
  state: {
      debugKey: 'XDEBUG_SESSION_START=',
      url: {
          beUrl: process.env.VUE_APP_BE_URL,
          beLogin: '/api/auth/signin',
          beLogout: '/api/auth/signout',
          beUserData: '/api/auth/self',
          beValidToken: '/api/auth/valid'
      },
      loginToken: 0
  },
    getters: {
        getUrl: state => {
            return state.url
        },
        getLoginToken: state => {
            return state.loginToken
        },
    },
    actions: {
        setLoginToken (store, data) {
            if(typeof (data.token) !== 'undefined') {
                store.commit('setLoginToken', data)
            }
        },
    },
  mutations: {
      setLoginToken (state, data) {
          Vue.set(state, 'loginToken', data.token)
      }
  }
})
