<template>
    <div class="container-fluid p-0 p-md-3 pb-md-5 position-relative">
        <gdpr :trigger="1" :gdprText="gdprText"></gdpr>
        <div class="p-2 pb-5 pt-md-4 pb-md-2 bg--trans--white rounded border-md border-md-light">
            <div class="row no-gutters">
                <div class="col-40 offset-8 col-md-22 offset-md-6 mt-4 mb-3 mb-md-0">
                    <logo :slogan="pageData.slogan"></logo>
                </div>
                <div class="col-40 offset-8 col-md-20 offset-md-0">
                    <addr-legal :contact="pageData.contact"></addr-legal>
                </div>
            </div>

            <div class="row no-gutters mt-3" v-html="pageData.main"></div>

            <div class="row no-gutters px-0 py-md-3 pt-5">
                <div class="col-md-36 offset-md-6 mb-3 pl-md-1">
                    <div class="input-group px-0 col-md-24">
                        <div class="input-group-prepend text-white">
                            <span class="input-group-text bg-light" id="imgCat">Referencie:</span>
                        </div>

                        <select class="border-left-0 form-control" aria-describedby="imgCat" v-model="gallerySelector">
                            <option v-for="(galleryOption, galleryOptionKey) in gallerySelectList" v-bind:key="galleryOptionKey"
                                    :value="galleryOption.option">{{galleryOption.label}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-md-36 offset-md-6 mb-5">
                    <image-slider :gallery="galleryShow"></image-slider>
                </div>
            </div>

            <div class="row no-gutters mb-4">
                <div class="col-44 offset-2" v-html="pageData.ending"></div>
            </div>
        </div>
        <footer class="position-absolute">
            <div class="text-right small">&copy; {{new Date().getFullYear()}} Ramid, všetky práva vyhradené.</div>
        </footer>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        name: 'Index',
        components: {
            Logo: () => import('@/components/Logo'),
            AddrLegal: () => import('@/components/AddrLegal'),
            ImageSlider: () => import('@/components/ImageSlider'),
            Gdpr: () => import('@/components/Gdpr'),
        },
        watch: {
            gallerySelector: function(newValue) {
                if(newValue) {
                    this.galleryShow = this.pageData.gallery[newValue]
                }
            }
        },
        computed: {
            gallerySelectList: function() {
                let reuturnValue = []
                if(typeof (this.pageData.gallery) != 'undefined') {
                    reuturnValue = Object.values(this.pageData.gallery).map((tmpGallery) => { return {'option': tmpGallery.id, 'label': tmpGallery.title }})
                }
                return reuturnValue
            }
        },
        data: function() {
            return {
                defaultPage: '/page/index/SK-sk',
                pageData: {},
                galleryShow: {},
                gallerySelector: '',
                gdprText: [
                    {
                        langcode: ['sk-SK', 'cz-CZ', 'sk', 'cz'],
                        title: 'Ochrana súkromia (GDPR)',
                        par1: 'Na prispôsobenie obsahu a analýzu návštevnosti používame súbory cookie.',
                        par2: 'Títo partneri môžu príslušné informácie skombinovať s ďalšími údajmi, ktoré ste im poskytli alebo ktoré od vás získali, keď ste používali ich služby.',
                        button: 'súhlasím'
                    },
                    {
                        default: true,
                        langcode: ['en-GB', 'en'],
                        title: 'Privacy Notice (GDPR <span class="small">...sorry for bothering you</span>)',
                        par1: 'In order to provide you the best content and traffic tracking, we do use Cookie collection.',
                        par2: 'This Data might be further stored and combined with various relevant information.',
                        button: 'agree'
                    }
                ]
            };
        },
        methods: {
            getPageData: function() {
                axios.get(process.env.VUE_APP_BE_URL + this.defaultPage, {
                    params: {
                         // XDEBUG_SESSION_START: 10909
                    },
                    responseType: 'json',
                })
                .then((response) => {
                    this.pageData = response.data
                    this.galleryShow = Object.values(this.pageData.gallery)[0]
                    this.gallerySelector = Object.values(this.pageData.gallery)[0].id
                });
            }
        },
        created() {

           this.getPageData();
        }
    };
</script>
