import Vue from 'vue'
import { mapGetters } from 'vuex'

Vue.mixin({
    computed: {
        ...mapGetters({
            configUrl: 'getUrl',
            loginToken: 'getLoginToken'
        })
    },
    methods: {
        checkIfMobile: function () {
            let width = (window.innerWidth > 0) ? window.innerWidth : screen.width
            let mobileDevice = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
            return width < 768 || mobileDevice
        },
        emailValid: function(email) {
            let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,9}))$/
            return (email == "") || (reg.test(email));
        },
        setPageTitle: function (newTitleText) {
            if(typeof(newTitleText) !== 'undefined') {
                let titleText = newTitleText.length > this.config.maxTitleLength ? newTitleText.slice(0, this.config.maxTitleLength) : newTitleText
                document.title = [this.config.pageName, titleText].join(' :: ')
            }
        }
    }
})
