import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'
import './mixin'
import './registerServiceWorker'
import vuexI18n from 'vuex-i18n'
/* eslint-disable no-debugger */
window.$ = window.jQuery = require('jquery')
window.bootstrap = require('bootstrap')

new Vue({
    router,
    store,
    // vuexI18n,
    render: h => h(App)
}).$mount('#app')

Vue.config.productionTip = false

Vue.use(vuexI18n.plugin, store, {
    moduleName: 'i18n',
    warnings: false,
    /*
    onTranslationNotFound (locale, key) {
            console.log(locale, key)
    }
    */
})


